/*
// .footer-links
*/
@import '../variables';
@import '../functions';


.footer-links {
    color: $footer-text-color;
}
.footer-links__title {
    margin-bottom: 22px;
}
.footer-links__list {
    font-size: 15px;
    line-height: 28px;
    list-style: none;
    padding: 0;
    margin: 0;

    a {
        color: $footer-text-color;
        transition: .15s;
        // &:hover {
        //     color: #003128 !important;
        // }
    }
    a:hover {
        color: $footer-hover-link;
    }
}
.footer-links__link{
    text-transform: capitalize;
}

@media (max-width: breakpoint(sm-end)) {
    .footer-links__title {
        margin-bottom: 12px;
    }
    .footer-links {
        margin-top: 42px;
        text-align: center;
    }
}
/*
// .mobilemenu
*/
@import "../variables";
@import "../mixins/direction";

$local-transition-duration: 0.3s;

.mobilemenu,
.mobile-submenu {
    visibility: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    transition: visibility 0s $local-transition-duration;

    @include direction {
        #{$inset-inline-start}: 0;
    }
}

.mobile-submenu {
    z-index: 1001;
    &.open {
        visibility: visible;
        transition-delay: 0s;

        .mobile-submenu__backdrop {
            opacity: 0.9;
        }

        .mobilemenu__body {
            @include direction {
                transform: translateX(0);
            }
        }
    }
}

.mobilemenu__backdrop,.mobile-submenu__backdrop {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: $mobilemenu-backdrop-bg;
    opacity: 0;
    transition: opacity $local-transition-duration;

    @include direction {
        #{$inset-inline-start}: 0;
    }
}
.mobilemenu__body {
    position: absolute;
    top: 0;
    width: 320px;
    height: 100%;
    background: $mobilemenu-bg;
    box-shadow: $mobilemenu-shadow;
    transition: transform $local-transition-duration;
    display: flex;
    flex-direction: column;

    @include direction {
        #{$inset-inline-start}: 0;
        transform: translateX(calc(-100% * #{$transform-direction} - 30px * #{$transform-direction}));
    }
}
.mobilemenu__header {
    height: $mobilemenu-header-height + 1px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    border-bottom: 1px solid $mobilemenu-divider-color;
}
.mobilemenu__title {
    padding: 0 20px;
    font-weight: $font-weight-bold;
    flex-grow: 1;
}
.mobilemenu__close {
    border: none;
    background: transparent;
    height: $mobilemenu-header-height;
    width: $mobilemenu-header-height + 1px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    fill: $mobilemenu-close-button-default-color;
    transition: fill 0.2s;

    @include direction {
        #{$border-inline-start}: 1px solid $mobilemenu-divider-color;
    }

    &:focus {
        outline: none;
    }
    &:focus,
    &:hover {
        fill: $mobilemenu-close-button-hover-color;
    }
}
.mobilemenu__content {
    flex-grow: 1;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .section {
        padding: 5px 20px 0px;
        // border-bottom: 1px solid $mobilemenu-divider-color;
        .section-title {
            text-transform: uppercase;
            color: $accent-color;
            font-size: 13px;
            margin-bottom: 12px;
            text-align: left;
        }
        .category-item,
        .select-item {
            list-style: none;
            padding: 6px 0;
            cursor: pointer;
            a {
                color: inherit;
            }
            &.disabled {
                color: dimgray;
                opacity: 0.5;
                cursor: unset;
            }
        }
        .select-item {
            padding: 10px 0;
            border-bottom: 1px solid $layout-switcher-default-border-color;
        }
    }
    .method-indicator {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        :first-child {
            font-weight: $font-weight-medium;
            font-size: 17px;
        }
        :last-child {
            margin-left: 10px;
        }
        .right-icon1 {
            fill: #ced0dd;
            svg {
                width: 21px;
                height: 21px;
            }
        }
        .right-icon2 {
            margin-top: 4px;
            fill: $accent-color;
        }
    }
}

.mobilemenu--open {
    visibility: visible;
    transition-delay: 0s;

    .mobilemenu__backdrop {
        opacity: 0.9;
    }

    .mobilemenu__body {
        @include direction {
            transform: translateX(0);
        }
    }
}

/*
// .cart
*/
@import "../variables";
@import "../functions";
@import "../mixins/buttons";
@import "../mixins/direction";

.cart {
    padding-bottom: 35px;
    .outline-label {
        font-size: 10px;
        position: relative;
    }
}
.cart__actions {
    margin-top: 1.75rem;
    display: flex;
    justify-content: space-between;
}
.cart__coupon-form {
    width: calc(50% - 15px);
    display: flex;

    .btn {
        flex-shrink: 0;

        @include direction {
            #{$margin-inline-start}: 0.5rem;
        }
    }
}
.cart__buttons {
    width: calc(50% - 15px);
    display: flex;
    justify-content: flex-end;
}
.cart__update-button {
    @include direction {
        #{$margin-inline-start}: 0.5rem;
    }
}
.cart__totals {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    margin-bottom: 40px;

    a:hover {
        text-decoration: underline;
    }

    th,
    td {
        vertical-align: top;
        padding: 6px 0;
    }

    th {
        font-weight: $font-weight-bold;
    }

    tr > :first-child {
        @include direction {
            #{$padding-inline-end}: 20px;
        }
    }

    tr > :last-child {
        @include direction {
            text-align: $inline-end;
        }
    }
}
.cart__totals-header {
    tr:last-child > * {
        padding-bottom: 10px;
    }
}
.cart__totals-body {
    tr:first-child > * {
        padding-top: 20px;
        border-top: 1px solid $cart-divider-color;
    }
    tr:last-child > * {
        padding-bottom: 20px;
        border-bottom: 1px solid $cart-divider-color;
    }
}
.cart__totals-footer {
    font-size: 24px;

    th {
        font-weight: $font-weight-medium;
    }

    tr:first-child > * {
        padding-top: 20px;
    }
}
.cart__calc-shipping {
    margin-top: 2px;
    font-size: 15px;
}

.cart-summary-bar,
.deals-summary-bar {
    border: 1px solid $table-border-color;
    margin-bottom: 15px;
    margin-top: 30px;
    border-radius: 8px;
    // position: sticky;
    // width: 100%;
    // z-index: 2;
    // bottom: 0px;
    // transition: cubic-bezier(0.4, 0, 0.6, 1);

    .title {
        display: flex;
        border-bottom: 1px solid $light-divider-color;
        padding: 15px;
        &.voucher-title {
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;
            padding: 15px 45px;
            .add-link {
                font-size: 15px;
            }
            .voucher-row {
                margin-right: 100px;
            }
            @media (max-width: 767px) {
                justify-content: space-between;
                .voucher-row {
                    margin-right: 50px;
                }
            }
            @media (max-width: 576px) {
                padding: 10px 15px;
                .voucher-row {
                    margin-right: 10px;
                }
            }
        }
        @media (max-width: 767px) {
            padding: 10px;
        }
        @media (max-width: 576px) {
            padding: 10px 5px;
        }
    }
    .content {
        padding: 25px 45px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 576px) {
            padding: 20px 20px;
        }
    }
    .footer {
        display: flex;
        border-top: 1px solid $light-divider-color;
        padding: 15px;
        @media (max-width: 767px) {
            padding: 10px;
        }
    }
    .cart-footer {
        text-align: right;
        width: 100%;
        @media (max-width: 576px) {
            .cart__checkout-button {
                height: auto;
                width: 100%;
            }
        }
    }
}
.deals-summary-bar {
    // border: 1px solid $block-highlighted-bg !important;
    // background-color: $block-highlighted-bg;
    .content {
        padding: 25px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
}

.voucher-row {
    display: flex;
    font-size: 15px;
    text-align: left;
    color: $medium-gray;
    svg {
        fill: $accent-color;
        margin-right: 10px;
        height: 23px;
    }
    a {
        margin-left: 10px;
        color: #4786cc;
    }
}
.subtotal {
    color: #818181;
    font-size: 15px;
}
.total-price {
    color: $accent-color;
    font-size: 23px;
    padding-left: 5px;
}
.cart__checkout-button {
    text-transform: uppercase;
    padding: 5px 65px;
}
.add-link {
    color: #4786cc;
    @media (max-width: 576px) {
        font-size: 15px;
    }
}
.checkout-summary-card {
    position: sticky;
    top: 100px;
}
@media (min-width: breakpoint(md-start)) and (max-width: breakpoint(md-end)) {
    .cart__actions {
        margin-top: 1.5rem;
    }
}
@media (max-width: breakpoint(sm-end)) {
    .cart__actions {
        margin-top: 1.5rem;
        display: block;
    }
    .cart__coupon-form {
        width: 100%;
    }
    .cart__buttons {
        flex-direction: column;
        margin-top: 1.75rem;
        width: 100%;
    }
    .cart__update-button {
        order: -1;
        margin-bottom: 0.75rem;

        @include direction {
            #{$margin-inline-start}: 0;
        }
    }
}
@media (max-width: 479px) {
    .cart__checkout-button {
        @include btn-lg();
    }
}
@media (max-width: 379px) {
    .cart__checkout-button {
        @include btn-nl();
    }
}

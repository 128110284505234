/*
// .site-footer
*/
@import "../variables";
@import "../functions";

.site-footer {
    // margin-top: 16px;
    background: $footer-bg;
    border-top: $footer-border-top;
}
.site-footer__widgets {
    padding: 48px 0 46px;
}
.site-footer__bottom {
    height: 54px;
    border-top: $footer-copyright-border;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .develop-info {
        font-size: 13px;
        color: $footer-text-color;
        &:hover {
            color: $footer-hover-link;
        }
    }
}
.site-footer__copyright {
    font-size: 13px;
    color: $footer-text-color;
    @media (max-width: 992px) {
        text-align: left;
    }
}
.site-footer__payments {
    img {
        max-width: 100%;
    }
}
.site-footer__widget {
    & + & {
        margin-top: 24px;
    }
}

.site-footer__social-icons {
    margin-left: auto;
    @media (max-width: breakpoint(sm-end)) {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}

.social-icons-list {
    list-style: none;
    padding-inline-start: 0;

    .social-icons {
        .social-icon {
            color: $footer-text-color;
            font-size: 17px;
            border-radius: 50%;
            line-height: 2.2em;
            width: 2.1em;
            height: 2.1em;
            text-align: center;
            display: inline-block;
        }

        i:hover {
            color: white;
            transform: translateY(-4px);
            transition: 0.4s;
        }
        img:hover {
            transform: translateY(-4px);
            transition: 0.4s;
        }
    }
}

@media (max-width: breakpoint(sm-end)) {
    .site-footer__widgets {
        padding-bottom: 48px;
    }

    .site-footer__bottom {
        padding: 20px 0 24px;
        flex-wrap: wrap;
        height: auto;
    }

    .site-footer__payments {
        order: -1;
    }

    .site-footer__copyright + .site-footer__payments {
        margin-bottom: 24px;
    }

    .site-footer__copyright,
    .develop-info,
    .site-footer__payments {
        width: 100%;
        text-align: center;
    }
}

.footer-logo {
    width: auto;
    height: 100px;
    margin-bottom: 10px;
}

.store-logo{
    cursor: pointer;
    width: 130px;
    height: auto;
    object-fit: contain;
    margin-bottom: 10px;
}
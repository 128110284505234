/*
// .site
*/
@import "../variables";

.site {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}
.site__header {
    flex-shrink: 0;
}
.site__body {
    flex-grow: 1;
    &.grey {
        background-color: $block-highlighted-bg;
    }
    @media (min-width: 992px) {
        padding-top: 120px;
    }
}
.site__footer {
    flex-shrink: 0;
}
